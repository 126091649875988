<template>
	<div>
		<div class="js-copy js-content pd_body" @scroll="handleScroll">
			<headerNav :isScrolled="isScrolled" :currentPath="currentPath">
			</headerNav>
			<div class="pd_banner">
				<div class="pd_banner_img">
					<img
						class="pd_banner_img_1"
						src="https://res.metaera.hk/resources/assets/images/event/39.png"
						alt=""
					/>
					<img
						class="pd_banner_img_2"
						src="https://res.metaera.hk/resources/assets/images/event/40.png"
						alt=""
					/>
					<div class="pd_banner_img_overlay"></div>
				</div>
				<div class="pd_banner_bottom"></div>
			</div>
			<div class="pd_about_1">
				<div class="pd_about_line"> </div>
				<div class="pd_about_line_red">
					<div class="pd_about_line_red_sub"> </div>
				</div>

				<div class="pd_about_title_top"> Polkadot & MetaEra </div>
				<div class="pd_about_title"> WHAT IS POLKADOT DECODED？ </div>

				<div class="pd_about_content">
					<div class="pd_about_left">
						<img
							src="https://res.metaera.hk/resources/assets/images/event/41.png"
							alt=""
						/>
					</div>
					<div class="pd_about_text">
						Polkadot Decoded Asia is the premier event in the Polkadot
						ecosystem, focusing on the dynamic Asian market while attracting a
						global audience. This event brings together developers, investors,
						enthusiasts, and industry leaders to delve into the latest
						blockchain advancements. With keynote speeches, panel discussions,
						fireside chats, AMAs, and exclusive announcements, it offers deep
						insights into Polkadot's groundbreaking innovations and
						applications. This year's Polkadot Decoded Asia will electrify
						Singapore on September 16-17, 2024, during the vibrant Singapore
						Token 2049 week. Don’t miss out—join us in Singapore!
					</div>
				</div>
			</div>
			<pdintroduceVue></pdintroduceVue>
			<div class="pd_about_line_1"></div>
			<bottom></bottom>
			<div class="demo" style="height: 50px"></div>
		</div>
	</div>
</template>

<script>
import headerNav from "./headern.vue";
import bottom from "./pdbottom.vue";
import pdintroduceVue from "./pdintroduce.vue";
import people from "./pdpeople.vue";

export default {
	name: "",
	components: {
		headerNav,
		bottom,
		people,
		pdintroduceVue,
	},
	data() {
		return {
			osafai: false,
			olead: false,
			isScrolled: true,
			currentPath: "/polkadot2024_about",
		};
	},
	created() {},
	methods: {
		handleScroll(event) {
			this.isScrolled = true;
		},
	},
};
</script>
<style scoped>
.pd_body {
	height: 100vh;
	overflow-y: scroll;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;

	background: #f7f8f9;
}
</style>

<!-- banner -->
<style scoped>
.pd_banner {
	margin-top: 80px;
	width: 100vw;
	height: calc(100vw * (1020 / 3840));
}

.pd_banner_img {
	width: 100%;
	height: auto;
}

.pd_banner_img_1 {
	display: flex;
}

.pd_banner_img_2 {
	display: none;
}
.pd_banner_img img {
	width: 100%;
	height: 100%;
}
.pd_banner_bottom {
	position: absolute;
	margin-left: 5%;
	margin-right: 5%;
	margin-top: -40px;
	height: 40px;
	width: 90%;
	background: white;
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;
	z-index: 10;
	display: none;
}
.pd_about_line_1 {
	display: none;
}
@media screen and (max-width: 900px) {
	.pd_banner_img_overlay {
		position: absolute;
		top: 50px;
		left: 0;
		width: 100%;
		height: calc(100vw * 660 / 1125);
		opacity: 0.3;
		background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 99%);
	}
	.pd_banner {
		margin-top: 50px;
		height: calc(100vw * 660 / 1125);
	}
	.pd_banner_img {
		width: 100%;
		height: 100%;
	}

	.pd_banner_img_1 {
		display: none;
	}

	.pd_banner_img_2 {
		display: flex;
	}
	.pd_banner_bottom {
		display: flex;
	}
	.pd_about_line_1 {
		display: block;
		width: 100%;
		height: 30px;
	}
}
</style>

<!-- pd_about -->
<style scoped>
/* pd_about */
.pd_about_1 {
	padding-top: 48px;
	padding-bottom: 0px;
}
.pd_about_line {
	display: none;
}
.pd_about_line_red {
	display: none;
}

.pd_about_line_red_sub {
	display: none;
}

.pd_about_title_top {
	display: block;
	margin-top: 3px;
	font-family: DINPro-Bold;
	font-size: 20px;
	font-weight: bold;
	line-height: normal;
	letter-spacing: 0em;
	flex-wrap: wrap;
	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
	align-content: center;
	text-align: center;
	align-items: center;
}
.pd_about_title {
	margin-top: 23px;
	margin-left: 120px;
	margin-right: 120px;
	font-family: DINPro-Bold;
	font-size: 60px;
	font-weight: bold;
	line-height: normal;
	letter-spacing: 0em;
	/* display: flex; */
	align-content: center;
	text-align: center;
	align-items: center;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}
.pd_about_content {
	width: 100%;
	padding-bottom: 60px;
	padding-top: 50px;
	display: flex;
	align-items: center; /* 可以根据需要调整对齐方式 */
}
.pd_about_left {
	margin-left: 10%;
	width: 420px;
	height: 290px;
	border-radius: 10px;
}
.pd_about_left img {
	width: 100%;
	border-radius: 10px;
}
.pd_about_text {
	width: 50%;
	margin-left: 30px;
	margin-right: 30px;
	border-radius: 20px;
	background: white;
	padding-left: 26px;
	padding-right: 26px;
	padding-top: 25px;
	padding-bottom: 25px;
	font-family: DINPro-Regular, DINPro;
	font-size: 20px;
	font-weight: normal;
	line-height: 30px;
	letter-spacing: 0em;
	text-align: justify;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}
@media screen and (max-width: 900px) {
	.pd_about_1 {
		padding-top: 8px;
		padding-bottom: 20px;
		background: white;
		margin-left: 5%;
		margin-right: 5%;
		border-bottom-right-radius: 40px;
		border-bottom-left-radius: 40px;
	}
	.pd_about_title_top {
		display: none;
	}
	.pd_about_line {
		display: flex;
		margin-left: 20px;
		margin-right: 20px;
		height: 1px;
		opacity: 1;
		background: #e7e7e7;
	}
	.pd_about_line_red {
		display: flex;
		justify-content: center; /* 水平居中 */
		align-items: center; /* 垂直居中，如果需要的话 */
	}

	.pd_about_line_red_sub {
		display: flex;
		margin-top: -1px;
		width: 80px;
		height: 2px;
		opacity: 1;
		background: #e6007a;
	}
	.pd_about_title {
		margin-top: 18px;
		margin-left: 23px;
		margin-right: 23px;
		font-family: DINPro-Bold;
		font-size: 24px;
	}
	.pd_about_content {
		width: 100%;
		padding-bottom: 0px;
		padding-top: 10px;
		display: flex;
		align-items: center;
		flex-direction: column; /* 垂直排列子元素 */
	}
	.pd_about_left {
		width: 80%;
		height: auto;
		margin-left: 0%;
		align-content: center;
		align-items: center;
		text-align: center;
		margin-top: 30px;
	}
	.pd_about_left img {
		width: 100%;
		height: auto;
		border-radius: 10px;
	}
	.pd_about_text {
		width: auto;
		padding-left: 0;
		padding-right: 0;
		margin-top: 20px;
		margin-left: 30px;
		margin-right: 30px;
		font-size: 14px;
	}
}
</style>
