import { render, staticRenderFns } from "./about.vue?vue&type=template&id=cec17ed0&scoped=true&"
import script from "./about.vue?vue&type=script&lang=js&"
export * from "./about.vue?vue&type=script&lang=js&"
import style0 from "./about.vue?vue&type=style&index=0&id=cec17ed0&prod&scoped=true&lang=css&"
import style1 from "./about.vue?vue&type=style&index=1&id=cec17ed0&prod&scoped=true&lang=css&"
import style2 from "./about.vue?vue&type=style&index=2&id=cec17ed0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cec17ed0",
  null
  
)

export default component.exports